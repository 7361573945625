:root {
  /* Colors  */
  --grey: #252525;
  --lightgrey: #818181;
  --buttons: #bbb1a1;
  --accentButtons: #d3506c;
  --white: #ffff;
  --dirtywhite: #ededed;

}


.App {
  text-align: center;
}

.App-section {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-section-title {
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.LoginLayout{
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.GreyBlock{
  height:25vh;
  width:100vw;
  background-color: var(--lightgrey);
}

.MainHeader{
  font-size: 2.5rem;
  font-weight: bold;
  margin: 30px 20px 30px 20px;
  width: 100vw;
  text-align: start;
  color: var(--grey);
}

.LoginForm{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 40vh;
}

.InsideForm{
  width: 80vw;

}

.Link{
  font-weight: bold;

}

#html5qr-code-full-region {
  background-color: #adb5bd;
  color: #282c34;
  font-size: 12pt;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@media(max-width: 640px) {
  #html5qr-code-full-region {
      width: 300px;
  }
}

@media(min-width: 640px) {
  #html5qr-code-full-region {
      width: 600px;
  }
}